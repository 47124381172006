<template>
  <div style="margin: 10px auto;width: 1200px;display: flex;flex-direction: row">

    <el-card class="user-nav-box">
      <div slot="header" class="clearfix" style="text-align: center;font-weight: bold;">
        <span>个人中心</span>
      </div>
      <div class="nav-list">
        <el-link href="#/my/collection">我的收藏</el-link>
        <el-link href="#/my/bookshelf">我的书架</el-link>
        <el-link href="#/my/browse">阅读记录</el-link>
        <el-link class="nav-select">基本信息</el-link>
      </div>
    </el-card>
    <div style="width: 900px;">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>我的资料</span>
        </div>
        <el-card class="info-box">
          <div class="el-card-info-box">
            <span>用户名:{{ userInfo.username }}</span>
            <edit-username @update="getUserInfo"></edit-username>
          </div>
        </el-card>
        <el-card class="info-box">
          <div class="el-card-info-box">
            <span>手机:{{ userInfo.phone }}</span>
            <edit-phone @update="getUserInfo"></edit-phone>
          </div>
        </el-card>
        <el-card class="info-box">
          <div class="el-card-info-box">
            <span>邮箱:{{ userInfo.email }}</span>
            <edit-email @update="getUserInfo"></edit-email>
          </div>
        </el-card>
        <el-card class="info-box">
          <div class="el-card-info-box">
            <span>密码:******</span>
            <edit-password @update="getUserInfo"></edit-password>
          </div>
        </el-card>
      </el-card>
    </div>
  </div>
</template>

<script>
import EditPhone from "../../components/my/editPhone";
import EditEmail from "../../components/my/editEmail";
import EditPassword from "../../components/my/editPassword";
import EditUsername from "../../components/my/editUsername";

export default {
  name: "info",
  components: {EditUsername, EditPassword, EditEmail, EditPhone},
  data() {
    return {
      userInfo: {},
    }
  },
  created() {
    if (!window.localStorage.getItem('access_token')) {
      let redirect = decodeURIComponent('/login');
      this.$router.push({
        path: redirect
      })
    } else {
      this.getUserInfo();
    }

  },
  methods: {
    getUserInfo() {
      let _this = this;
      _this.axios.get('user/get-info')
          .then(function (res) {
            if (res.code == 200) {
              _this.userInfo = res.data;
            } else {
              _this.$message.error(res.msg);
            }
          });
    },

    openBookInfo(bookId) {
      this.$router.push({path: '/book/bookInfo', query: {bookId: bookId}});
    }
  },
}
</script>

<style scoped lang="less">
.home {
  width: 100%;
}

.user-nav-box {
  width: 200px;
  margin-right: 30px;
  height: 400px;

  .nav-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 40px;
  }

  .nav-select {
    font-weight: bold;
    color: #409EFF;
  }
}

.info-box {

  margin-bottom: 12px;
}

.el-card-info-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 50px;
  align-items: center;
}
</style>