<template>
  <div>
    <el-button size="small" type="primary" @click="dialogVisible = true">修改手机</el-button>
    <el-dialog
        title="手机号码修改"
        :visible.sync="dialogVisible"
        width="400px"
        :before-close="handleClose">
      <div style="height: 100px;">
        <el-form :model="submitForm" status-icon :rules="submitFormRules" ref="submitForm" label-width="0"
                 label-position="right" class="demo-submitForm">
          <el-form-item prop="phone">
            <el-input type="text" v-model="submitForm.phone" autocomplete="off" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="submitForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import JSEncrypt from "jsencrypt";
import store from "../../store";
import * as types from "../../store/type";

export default {
  name: "editPhone",
  data() {
    return {
      dialogVisible: false,
      submitForm: {
        phone: '',
        password: ''
      },
      submitFormRules: {
        phone: [
          {required: true, trigger: 'blur', message: '请输入手机号'}
        ],
        password: [
          {required: true, trigger: 'blur', message: '请输入密码'}
        ],
      }
    }
  },
  watch: {
    dialogVisible() {
      if (this.dialogVisible == false) {
        this.submitForm = {
          phone: '',
          password: ''
        }
      }
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    submit() {
      let _this = this;
      _this.$refs['submitForm'].validate((valid) => {
        if (valid) {
          let encrypt = new JSEncrypt();
          encrypt.setPublicKey(_this.global.PUBLICKEY);
          let params = {
            password: encodeURI(encrypt.encrypt(_this.submitForm.password)).replace(/\+/g, '%2B'),
            phone: encodeURI(encrypt.encrypt(_this.submitForm.phone)).replace(/\+/g, '%2B'),
          };
          _this.axios.post('/user/modify_phone', params)
              .then(function (res) {
                if (res.code == 200) {
                  _this.$message.success('修改成功');
                  _this.$emit('update')
                  _this.handleClose();
                } else {
                  _this.$message.error(res.msg);
                }
              })
              .catch(function (error) {
                console.log(error);
              });

        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>